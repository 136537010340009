import { intlFormat } from 'date-fns';

const Formating = {
  date: (date, locale = 'EN') => {
    const dateToFormat = typeof date === 'string' ? new Date(date) : date;

    return intlFormat(
      dateToFormat,
      {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      },
      {
        locale,
      }
    );
  },
};

export default Formating;
