import { useStaticQuery, graphql } from 'gatsby';
import useLocale from 'hooks/useLocale';

const query = graphql`
  query categoriesQuery {
    allWpCategory {
      nodes {
        id
        slug
        name
        description
        count
        language {
          code
        }
      }
    }
  }
`;

const useCategories = () => {
  const currentLocale = useLocale();

  const {
    allWpCategory: { nodes },
  } = useStaticQuery(query);

  return nodes
    .filter(({ description }) => !description && description !== 'hidden')
    .filter(({ language }) => language.code === currentLocale.code)
    .sort((a, b) => b.count - a.count);
};

export default useCategories;
