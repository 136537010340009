import { useStaticQuery, graphql } from 'gatsby';
import useLocale from 'hooks/useLocale';

const pageByTemplate = graphql`
  query {
    allWpPage {
      edges {
        node {
          uri
          language {
            code
          }
          template {
            templateName
          }
        }
      }
    }
  }
`;

const usePageBasicData = (template = null) => {
  const locale = useLocale();

  const {
    allWpPage: { edges },
  } = useStaticQuery(pageByTemplate);

  return edges
    .filter(({ node }) => node.language.code === locale.code)
    .find(({ node }) => !template || node.template.templateName === template);
};

export default usePageBasicData;
