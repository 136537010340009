import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useAllMedia from 'hooks/graphql/useAllMedia';

const WpImage = ({ src, alt = '', ...props }) => {
  const allImages = useAllMedia();

  const image = allImages.find(({ node }) => node.sourceUrl === src);

  return image == null ? (
    <img key={src} src={src} alt={alt} {...props} />
  ) : (
    <GatsbyImage
      key={src}
      image={getImage(image.node.localFile.childImageSharp)}
      alt={alt}
      {...props}
      placeholder="blurred"
      layout="fixed"
    />
  );
};

export default WpImage;
