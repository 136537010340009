import { useStaticQuery, graphql } from 'gatsby';

const allMedia = graphql`
  query {
    allWpMediaItem {
      edges {
        node {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const useAllMedia = () => {
  const {
    allWpMediaItem: { edges },
  } = useStaticQuery(allMedia);

  return edges;
};

export default useAllMedia;
