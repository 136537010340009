import React from 'react';
// import FaviconLogo from 'assets/svg/faviconLogo.inline.svg';
// import 'assets/scss/components/loader.scss';

// TODO: удалить?
const Loader = () => {
  return (
    <div className="ct-loader-wrap">
      <div className="ct-loader">
        Loader...
        {/* <FaviconLogo />
        <p className="ct-h2 ct-txt-strong ct-loader__title">Capptoo</p> */}
      </div>
    </div>
  );
};

export default Loader;
