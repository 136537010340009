// import removeEmpty from "../utils/removeEmptyObjectKeys";
// import merge from "../utils/mergeDeepObjects";

const getData = (data) => {
  if (
    !data.revisions ||
    !data.revisions.nodes ||
    data.revisions.nodes.length === 0
  )
    return data;

  const { revisions, ...page } = data;

  const lastRevision = Array.from(revisions.nodes)
    .reverse()
    .reduce((obj, revision) => ({ ...obj, ...revision }), { ...page });

  // const lastRevision = data.revisions.nodes[0];

  if (!lastRevision) return data;

  // const { revisions, ...originalContent } = data;
  // return merge(originalContent, removeEmpty(lastRevision));

  return lastRevision;
};

export default getData;
