const showBlogsSection = (posts, pageCategories = []) => {
  if (!pageCategories || pageCategories.length === 0) return false;

  return (
    posts.filter(({ categories: { nodes } }) =>
      nodes
        .map(({ slug }) => slug)
        .some((slug) => pageCategories.map(({ slug }) => slug).includes(slug))
    ).length > 0
  );
};

export default showBlogsSection;
