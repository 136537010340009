import React, { useState } from 'react';
import NewsList from '../NewsList';
import Filter from '../Filter';

import useCategories from 'hooks/graphql/useCategories';

const News = ({ posts, showFilters, categoriesOnPage }) => {
  const [activeCategorySlug, setActiveCategory] = useState(null);
  const filters = useCategories();

  const filteredCategories = filters.filter(({ slug }) =>
    posts.some(({ categories: { nodes } }) =>
      nodes.map(({ slug }) => slug).includes(slug)
    )
  );

  let filteredPost = activeCategorySlug
    ? posts.filter(({ categories }) =>
        categories.nodes.map(({ slug }) => slug).includes(activeCategorySlug)
      )
    : [...posts];

  // Filter posts by current page categories
  if (categoriesOnPage.length)
    filteredPost = filteredPost.filter(({ categories: { nodes } }) =>
      nodes
        .map(({ slug }) => slug)
        .some((slug) => categoriesOnPage.map(({ slug }) => slug).includes(slug))
    );

  // Show only 3 newest
  if (filteredPost.length > 3) filteredPost.length = 3;

  const handleActiveItem = (slug) => {
    setActiveCategory(slug);
  };

  return (
    <div>
      <div>
        {showFilters && (
          <Filter
            filters={filteredCategories}
            onActive={handleActiveItem}
            isActiveItem={activeCategorySlug}
          />
        )}

        <NewsList news={filteredPost} />
      </div>
    </div>
  );
};

export default News;
