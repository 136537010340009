import React from 'react';
import classnames from 'classnames';

import NewsCard from '../NewsCard';

import useIntersection from 'hooks/useIntersection';

import { MOBILE_KEY } from 'constants/devices';
import useDeviceState from 'hooks/useDeviceState';

import * as styles from './newsList.module.scss';

const NewsList = ({ news }) => {
  const device = useDeviceState();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: device === MOBILE_KEY ? 0.3 : 0.7,
  });

  return (
    <div className={styles.listwrap} ref={scrollWrapper}>
      <ul
        className={classnames(styles.list, inViewSec && styles.jsAnimInit)}
        ref={inViewRefSec}
      >
        {news.map((news) => (
          <li key={news.id}>
            <NewsCard news={news} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsList;
