import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import Icon from 'components/UI/Icon';
import News from './components/News';

import useIntersection from 'hooks/useIntersection';

import * as styles from './blog.module.scss';
import usePageBasicData from 'hooks/graphql/usePageBasicData';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import showBlogsSection from '@/helpers/showBlogsSection';
import useBlogPosts from 'hooks/graphql/useBlogPosts';

const Blog = ({ data, noMargin }) => {
  const posts = useBlogPosts();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  const {
    forms_ui: {
      buttons: { readMore },
    },
  } = useReusableBlocks();

  const {
    node: { uri },
  } = usePageBasicData('Blog');

  if (
    !posts.length ||
    (!data.showFilters && !showBlogsSection(posts, data.blogCategoriesOnPage))
  )
    return null;

  return (
    <section
      className={classnames(styles.section, {
        [styles.no_margin]: noMargin,
      })}
      ref={scrollWrapper}
    >
      <div className="cr-wrap">
        <div
          className={classnames(styles.inner, inViewSec && styles.jsAnimInit)}
        >
          <div className={classnames(styles.header)} ref={inViewRefSec}>
            {data.subtitle && (
              <p
                className={classnames(
                  styles.subtitle,
                  'cr-subheader cr-text-3'
                )}
              >
                {data.subtitle}
              </p>
            )}
            <h2 className={classnames('cr-h2')}>{data.title}</h2>
          </div>
          <News
            posts={posts}
            categoriesOnPage={data.blogCategoriesOnPage || {}}
            showFilters={data.showFilters}
          />

          <Link
            to={uri}
            className={classnames(
              styles.link,
              'cr-btn cr-btn--icon cr-btn--icon-arrow cr-btn--large cr-btn--wide cr-btn--icon-red-txt'
            )}
          >
            {readMore}
            <Icon name="corner-arrow-small-bold" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Blog;
