import React, { forwardRef } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Icon from 'components/UI/Icon';

const SliderBtn = (
  {
    className = '',
    onClick = () => {},
    mod = 'dark',
    type = 'left',
    Tag = 'button',
  },
  ref
) => (
  <Tag
    ref={ref}
    type="button"
    className={classnames(styles.btn, styles[mod], className)}
    onClick={onClick}
  >
    <Icon name={`arrow-${type}`} className={styles.btn_icon} />
  </Tag>
);

export default forwardRef(SliderBtn);
