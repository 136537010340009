import React, { useMemo } from 'react';
import classnames from 'classnames';

import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const Join = () => {
  const { joinSection: data } = useReusableBlocks();
  const getStaticImage = useStaticImage();

  const { openGetCirrusModal } = useGetCirrusoftModal();

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(getStaticImage('common/join/img-desktop.jpg').childImageSharp),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('common/join/img-tablet.jpg').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('common/join/img-mobile.jpg').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <section className={styles.joinsec}>
      <div className={classnames('cr-wrap', styles.join)}>
        <div className={styles.join_txt}>
          <div className={styles.jointxt}>
            <h2 className={classnames('cr-h2 cr-txt-1', styles.jointxt_title)}>
              {data.title}
            </h2>
            <p
              className={classnames('cr-reg cr-txt-2', styles.jointxt_subtitle)}
            >
              {data.description}
            </p>
            <Button
              className={classnames(
                'cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg',
                styles.jointxt_btn
              )}
              onClick={openGetCirrusModal}
            >
              {data.link?.text}
              <Icon name="corner-arrow-small-bold" />
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.joinsec_bg}>
        <GatsbyImage image={images} alt={data.title} />
      </div>
    </section>
  );
};

export default Join;
