import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';

import * as styles from './filter.module.scss';

const Filter = ({ filters, onActive, isActiveItem }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <div className={classnames(styles.filter)} ref={scrollWrapper}>
      <ul
        className={classnames(
          styles.list,
          inViewSec && styles.jsAnimInit,
          'cr-underline-list'
        )}
        ref={inViewRefSec}
      >
        {filters.length > 0 &&
          filters.map(({ slug, name, id }) => {
            return (
              <li
                key={id}
                className={classnames(
                  styles.listItem,
                  slug === isActiveItem ? 'cr-markered' : ''
                )}
              >
                <button type="button" onClick={() => onActive(slug)}>
                  {name}
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Filter;
