import { useStaticQuery, graphql } from 'gatsby';
import useLocale from 'hooks/useLocale';

const query = graphql`
  query BlogPostsQuery {
    allWpPost {
      nodes {
        id
        language {
          code
        }
        blog {
          itemSubtitle
          similar {
            ... on WpPost {
              id
              blog {
                banner {
                  sourceUrl
                }
              }
              title
              dateGmt
              uri
            }
          }
          textContent
          banner {
            title
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        dateGmt
        link
        seo {
          metaDesc
          fullHead
        }
        slug
        title
        uri
        author {
          node {
            username
            seo {
              title
            }
            nickname
            nicename
            name
            firstName
            lastName
            description
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;

const useBlogPosts = () => {
  const currentLocale = useLocale();

  const {
    allWpPost: { nodes },
  } = useStaticQuery(query);

  return nodes
    .filter(({ language }) => language.code === currentLocale.code)
    .sort((a, b) => (new Date(a.dateGmt) > new Date(b.dateGmt) ? 1 : 0));
};

export default useBlogPosts;
