import React from 'react';
import classnames from 'classnames';
import { intlFormat } from 'date-fns';
import { Link } from 'gatsby';

import useLocale from 'hooks/useLocale';
import WpImage from 'components/Elements/WPImage';

import * as styles from './styles.module.scss';

const NewsCardSmall = ({ news }) => {
  const locale = useLocale();

  const { blog, uri, title, dateGmt } = news;

  return (
    <Link to={uri} className={styles.news}>
      <div className={styles.news_image}>
        <WpImage
          src={blog?.banner?.sourceUrl || '#'}
          alt={title}
          className={styles.news_image_inner}
        />
      </div>
      <div className={styles.news_txt}>
        <p className={classnames('cr-lead', styles.news_txt_title)}>{title}</p>

        <p className={classnames('cr-sub cr-text-2', styles.news_txt_date)}>
          {intlFormat(
            new Date(dateGmt),
            {
              month: 'long',
              day: 'numeric',
            },
            {
              locale: locale.code,
            }
          )}
        </p>
      </div>
    </Link>
  );
};

export default NewsCardSmall;
